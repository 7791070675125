<nb-card>
  <nb-card-body class="flex-column">
    <nb-auth-block class="flex-centered w-75">
      <ngx-intake-header title="Change your password" styleClasses="mt-0"></ngx-intake-header>
      <form (ngSubmit)="changePassword()" #passwordChangeForm="ngForm">

        <ngx-request-errors *ngIf="showMessages.error && !submitted" [errors]="errors"></ngx-request-errors>
        <div *ngIf="showMessages.success && messages && messages.length > 0 && !submitted" class="alert alert-success"
          role="alert">
          <div>
            <strong>Hooray!</strong>
          </div>
          <div *ngFor="let message of messages">{{ message }}</div>
        </div>



        <div class="form-group">
          <label for="current_password" class="sr-only">Current Password</label>
          <input name="current_password" [(ngModel)]="user.current_password" type="password" id="current_password"
            aria-describedby="current_password" class="form-control" placeholder="current password"
            #current_password="ngModel"
            [class.form-control-danger]="current_password.invalid && current_password.touched"
            [required]="getConfigValue('forms.validation.password.required')">
          <small class="form-text error"
            *ngIf="current_password.invalid && current_password.touched && current_password.errors?.required">
            Current password is required!
          </small>
          <small id="passwordHelp" class="form-text text-muted">You must provide your current password in order to
            change it.
          </small>
        </div>

        <div class="form-group">
          <label for="new_password" class="sr-only">New Password</label>
          <input name="new_password" [(ngModel)]="user.new_password" type="password" id="new_password"
            class="form-control form-control-lg first" placeholder="new password" #new_password="ngModel"
            [class.form-control-danger]="new_password.invalid && new_password.touched"
            [required]="getConfigValue('forms.validation.password.required')"
            [minlength]="getConfigValue('forms.validation.password.minLength')"
            [maxlength]="getConfigValue('forms.validation.password.maxLength')" autofocus ngxValidatePassword>
          <small class="form-text error"
            *ngIf="new_password.invalid && new_password.touched && new_password.errors?.required">
            Password is required!
          </small>
          <small class="form-text error" *ngIf="(new_password.invalid && new_password.touched && (new_password.errors?.minlength || new_password.errors?.maxlength))
                || (new_password.touched && new_password.errors?.passwordInvalid)">
            Password must contain at lease one uppercase letter, one lowercase letter, one number and be at least
            {{getConfigValue('forms.validation.password.minLength')}} characters long
          </small>
        </div>

        <div class="form-group">
          <label for="confirm_password" class="sr-only">Current Password</label>
          <input name="confirm_password" [(ngModel)]="user.confirm_password" type="password" id="confirm_password"
            aria-describedby="confirm_password" class="form-control" placeholder="confirm password"
            #confirm_password="ngModel"
            [class.form-control-danger]="confirm_password.invalid && confirm_password.touched"
            [required]="getConfigValue('forms.validation.password.required')">
          <small class="form-text error"
            *ngIf="confirm_password.invalid && confirm_password.touched && confirm_password.errors?.required">
            Password confirmation is required!
          </small>
          <small class="form-text error"
            *ngIf="confirm_password && new_password.value != confirm_password.value && !confirm_password.required">
            Password does not match the confirm password.
          </small>
        </div>
        <button [disabled]="submitted || !passwordChangeForm.valid || new_password.value != confirm_password.value" class="btn btn-block btn-primary"
          [class.btn-pulse]="submitted">
          Save Changes
        </button>
      </form>
    </nb-auth-block>
  </nb-card-body>
</nb-card>